body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Arvo';
  font-display: auto;
  src: local('Arvo'), url(https://fonts.gstatic.com/s/arvo/v9/rC7kKhY-eUDY-ucISTIf5PesZW2xOQ-xsNqO47m55DA.woff2) format('woff2');
}
.App {
  text-align: center;
}

.app-header {
  background-color: #1f202b !important;
  overflow: hidden !important;
}

.app-footer {
  background-color: #1f202b !important;
}

.menu-noborder {
  border-width: 0px !important;
}

.column-hover:hover {
  background-color: #e2e6ec;
}

.logo-clickable {
  color: #fff;
}

.logo-clickable:hover {
  color: #fff;
}

.neon:hover {
  color: #fff;
  text-shadow:
    0 0 5px #fff;
}

.App {
  text-align: center;
}

.app-header {
  background-color: #1f202b !important;
  overflow: hidden !important;
}

.app-footer {
  background-color: #1f202b !important;
}

.menu-noborder {
  border-width: 0px !important;
}

.column-hover:hover {
  background-color: #e2e6ec;
}

.logo-clickable {
  color: #fff;
}

.logo-clickable:hover {
  color: #fff;
}

.neon:hover {
  color: #fff;
  text-shadow:
    0 0 5px #fff;
}